import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ForgeLoader from "../assets/svg/ForgeLoader";
import axios from "axios";
import IMXLogo from "../assets/svg/ImmutableLogo";
import MetamaskLogo from "../assets/svg/MetamaskLogo";
import WombatLogo from "../assets/img/wombat-logo-login.png";
import PassportLogo from "../assets/img/passport-logo.png";
import LoaderGlass from "../assets/svg/LoaderGlass";
import OpenLootLogo2 from '../assets/img/OpenLootLogo.png';
import { Ethereum, ImmutableX, ImmutablePassport, Toast } from "../utils/Global";
import { useSphereOneProvider } from "../utils/SphereOneHooks";
import OTPInput from "../components/OTP";
import { Context } from "../context/WalletContext";
import { passportInstance } from "../context/PassportService";

const qorbiServices = require('../context/QorbiService');
const immutableServices = require('../context/ImmutableService');

const UserProfile = () => {

    const storedItem = localStorage.getItem('unauthorizedPath');
    const { immutableLogin, LoginWallet, metamaskLogin, localLogin, state } = useContext(Context);
    const { setRegisterWallet, setRegisterUser, setQorbiUserData, qorbiUserData } = useSphereOneProvider();
    const [loading, setLoading] = useState(true);
    const [migrationLoader, setMigrationLoader] = useState(false);
    const [showOTP, setShowOTP] = useState(false);
    const [hotNews, setHotNews] = useState([]);
    const [isQorbOwner, setIsQorbOwner] = useState(false);

    const [pendingMigrations, setPendingMigrations] = useState({ total: 0, completed: 0 })
    const location = useLocation();
    const isLoggedIn = state.user !== null;

    // const redirect_uri = "http://localhost:3000/profile"
    const redirect_uri = "https://qorbiworld.com/profile"

    const getNews = async () => {
        const hotNewsResponse = await axios.get('https://api.qorbiworld.com/v1/hotnews');
        setHotNews(hotNewsResponse.data);
    }

    useEffect(() => {
        qorbOwner();
        getNews();
        try {
            passportInstance.loginCallback();
        } catch (error) {
            console.error(error);
        }
        const params = new URLSearchParams(location.search);
        const code = params.get("code");
        const openLootSource = localStorage.getItem('OpenLootSource')

        const fetchAndSetOpenLootInfo = async (source) => {
            localStorage.removeItem('OpenLootSource')
            if (source === 'connect' && !qorbiUserData.openLootInfo) {
                const openLootData = await qorbiServices.updateOpenLootUserData(code, qorbiUserData._id, redirect_uri)
                setQorbiUserData((prevData) => ({
                    ...prevData,
                    openLootInfo: openLootData
                }));
            } else if (source === 'auth') {
                const openLootData = await qorbiServices.getOpenLootUserData(code, qorbiUserData._id, redirect_uri)
                setMigrationLoader(true)
                while (true) {
                    const canMigrate = await qorbiServices.availableOpenlootMigration();
                    if (canMigrate.available) {
                        const trackId = await qorbiServices.migrateAssetsFromOpenLoot(openLootData.sessionsToken.accessToken, state.user.data);
                        try {
                            while (true) {
                                const migrationStatus = await qorbiServices.trackMigration(trackId.followId);
                                const completedCount = migrationStatus.filter(item => item.finished).length;
                                setPendingMigrations({ completed: completedCount, total: migrationStatus.length })
                                const allFinished = migrationStatus.every(item => item.finished);
                                if (allFinished) {
                                    const allSuccessful = migrationStatus.every(item => item.success);
                                    if (!allSuccessful) {
                                        setMigrationLoader(false);
                                        Toast.set({
                                            show: true,
                                            desc: 'Oops! Some migrations failed. Please contact us using discord. CODE: OL-ZKEVM',
                                            type: 'error',
                                        });
                                    } else {
                                        setMigrationLoader(false);
                                        Toast.set({
                                            show: true,
                                            desc: 'All migrations completed successfully!',
                                            type: 'success',
                                        });
                                    }
                                    break;
                                }
                                await new Promise(resolve => setTimeout(resolve, 3000));
                            }
                        } catch (error) {
                            console.error("Error tracking migration:", error);
                            throw error;
                        }
                        break;
                    }
                    await new Promise(resolve => setTimeout(resolve, 3000));
                }
            }
        }
        if (qorbiUserData) {
            setLoading(false);
            if (code) {
                fetchAndSetOpenLootInfo(openLootSource)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [qorbiUserData]);

    const handleOtpSubmit = async (otpCode) => {
        setShowOTP(false)
        try {
            const result = await qorbiServices.checkAndSpend(qorbiUserData.email, otpCode);
            if (result) {
                Toast.set({
                    show: true,
                    desc: 'Success! You’ve been added to the Mystery Box whitelist and your information has been processed. Get ready for your exclusive Mystery Box!',
                    type: 'success',
                });
                setQorbiUserData((prevData) => ({
                    ...prevData,
                    openlootWhitelist: true,
                }));
            }
        } catch (error) {
            console.error(error);
        }
    };

    const handleCloseOtpSubmit = () => {
        setShowOTP(false)
    };

    const isIMX = async (address) => {
        try {
            const immutableResponse = await axios.get(`https://api.x.immutable.com/v1/users/${address}`);
            return !!immutableResponse.data;
        } catch (immutableError) {
            return false;
        }
    };

    const validateWallet = async (address) => {
        const isImx = await isIMX(address);
        if (isImx) {
            try {
                const userResponse = await qorbiServices.getQorbiUserSafe(address);
                setQorbiUserData(userResponse);
            } catch (error) {
                setRegisterUser(true);
            }
        } else {
            setRegisterWallet({ show: true, wallet: address });
        }
    };

    const connect_Immutable = async () => {
        try {
            if ("ethereum" in window) {
                const userAddress = await immutableLogin();
                if (userAddress.data) {
                    const userInfo = {
                        authType: ImmutableX,
                        data: userAddress.data,
                        isWombat: undefined
                    };
                    localStorage.setItem('userInfo', JSON.stringify(userInfo));
                    validateWallet(userInfo.data);
                    localLogin(userInfo);
                    if (storedItem) {
                        localStorage.removeItem('unauthorizedPath');
                        window.location.href = storedItem;
                    }
                }
            } else {
                LoginWallet();
            }
        } catch (error) {
            console.error(error);
        }
    };

    const connect_Passport = async () => {
        try {
            setLoading(true)
            const provider = passportInstance.connectEvm();
            const accounts = await provider.request({ method: 'eth_requestAccounts' });
            const userProfile = await passportInstance.getUserInfo();
            const userInfo = {
                authType: ImmutablePassport,
                data: accounts[0],
                isWombat: undefined
            };
            localStorage.setItem('userInfo', JSON.stringify(userInfo));
            try {
                const userResponse = await qorbiServices.getQorbiUserSafe(userProfile.email);
                setQorbiUserData(userResponse);
            } catch (error) {
                setRegisterUser(true);
            }
            localLogin(userInfo);
            setLoading(false)
            if (storedItem) {
                localStorage.removeItem('unauthorizedPath');
                window.location.href = storedItem;
            }
        } catch (error) {
            console.error(error);
        }
        window.location.reload();
    };

    const connect_eth_provider = async () => {
        try {
            if ("ethereum" in window) {
                const userAddress = await metamaskLogin();
                if (userAddress.data) {
                    const userInfo = {
                        authType: Ethereum,
                        data: userAddress.data,
                        isWombat: window.ethereum.isWombat
                    };
                    localStorage.setItem('userInfo', JSON.stringify(userInfo));
                    validateWallet(userInfo.data);
                    localLogin(userInfo);
                    if (storedItem) {
                        localStorage.removeItem('unauthorizedPath');
                        window.location.href = storedItem;
                    }
                }
            } else {
                Toast.set({
                    show: true,
                    desc: (
                        <p>
                            Metamask is not installed. <br />
                            <a href="https://metamask.io/" target="_blank" rel="noreferrer">
                                Metamask
                            </a>
                        </p>
                    ),
                });
            }
        } catch (error) {
            console.error(error);
        }
    };

    const connectOpenLoot = async (source) => {
        localStorage.setItem('OpenLootSource', source)
        const clientId = "f490040a-9d54-4611-819c-eda21281e5e1";
        // const clientId = "695f4869-6fa2-4c7e-a77c-1c3704a26b59";
        const responseType = "code";
        const authUrl = `https://api.openloot.com/oauth/v1/authorize?client_id=${clientId}&redirect_uri=${encodeURIComponent(redirect_uri)}&response_type=${responseType}`;
        window.location.href = authUrl;
    };

    const qorbOwner = async () => {
        setIsQorbOwner(await immutableServices.assetOwner(state.user.data, 'qorb'))
    }

    if (loading || !isLoggedIn) {
        return (
            <div className="loading-magnifying-glass">
                <LoaderGlass></LoaderGlass>
                <h1 className="banner-title">Getting Information from the librarian!!!</h1>
                <h2 className="banner-title">Yes, we have a librarian...</h2>
                <div className="signin-overlay">
                    <div className="signin-box">
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <h1>SIGN IN</h1>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <div className="welcome">
                                <p style={{ marginRight: 3 }}>Welcome to</p>
                                <p style={{ color: "green" }}>Qorbi World</p>
                            </div>
                        </div>
                        <p className="welcome">Login using Gmail-Email-Apple</p>
                        <div className="signin-buttons">
                            <button onClick={connect_Passport} style={{ backgroundColor: "#14b3cb" }}>
                                <img src={PassportLogo} alt="Passport Logo" width={25} height={25} /> Login with Passport
                            </button>
                        </div>
                        <p className="welcome">Or connect with your wallet</p>
                        <div className="signin-buttons">
                            <button onClick={connect_eth_provider} style={{ backgroundColor: "#eb8022" }}>
                                <MetamaskLogo /> Connect with Metamask
                            </button>
                            <button onClick={connect_eth_provider} style={{ backgroundColor: "#FF4F24" }}>
                                <img src={WombatLogo} alt="Wombat Logo" width={25} height={25} /> Connect with Wombat
                            </button>
                            <button onClick={connect_Immutable} style={{ backgroundColor: "#14b3cb" }}>
                                <IMXLogo /> Connect with ImmutableX
                            </button>

                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="profile-container">
            {migrationLoader && (
                <div className="loader-layer-1">
                    <div className="loader-layer-2">
                        <ForgeLoader />
                        <h2 style={{ margin: '10px 0' }}>Migrating Assets</h2>
                        <h2 style={{ margin: '10px 0' }}>{pendingMigrations.completed}/{pendingMigrations.total} Completed</h2>
                    </div>
                </div>
            )}
            <div className="left-side-profile">
                <div className="profile-main-rectangle">
                    <h1 className="profile-username">{qorbiUserData?.username}</h1>
                    <p className="profile-email">{qorbiUserData?.email}</p>
                    <div className="profile-level">
                        Level: {qorbiUserData?.rank?.level}
                    </div>
                </div>

                <div className="profile-secondary-rectangle">
                    {qorbiUserData?.on3Account?.balance &&
                        <div className="profile-info-row" style={{ color: "#008000" }}>
                            Balance: {qorbiUserData?.on3Account?.balance['QORBI']} QORBI
                        </div>
                    }
                    <div className="profile-info-row">
                        Winrate: {qorbiUserData?.winrate || 'N/A'}
                    </div>
                    <div className="profile-info-row">
                        Score: {qorbiUserData?.rank.totalScore}
                    </div>

                    <div className="profile-info-row" onClick={() => navigator.clipboard.writeText(qorbiUserData?.referalCode)} style={{ cursor: "pointer" }}>
                        Referral Code: {qorbiUserData?.referalCode || 'Generating'}
                    </div>
                </div>
                {
                    !qorbiUserData?.openLootInfo ? (
                        <button onClick={() => { connectOpenLoot('connect') }}
                            className="connect-open-loot-button">
                            <img src={OpenLootLogo2} alt="Open Loot Logo" className="connect-open-loot-logo" />
                            <span className="connect-open-loot-text">Connect</span>
                        </button>
                    ) : (
                        <div className="connect-open-loot-button" style={{ cursor: "default" }}>
                            <img src={OpenLootLogo2} alt="Open Loot Logo" className="connect-open-loot-logo" />
                            <span className="connect-open-loot-text">{qorbiUserData.openLootInfo.username}</span>
                        </div>
                    )
                }
            </div>
            {showOTP && <OTPInput onSubmit={handleOtpSubmit} onCancel={handleCloseOtpSubmit} />}
            <div className="right-side-profile">
                <h1 className="profile-tournaments-title">Hot News</h1>
                <table className="profile-tournaments-table">
                    <thead>
                        <tr>
                            <th>Relevant Now</th>
                        </tr>
                    </thead>
                    <tbody>
                        {hotNews?.length > 0 ? (
                            hotNews.map(news => (
                                <tr key={news._id}>
                                    <td>{news.simpleText} {news.link && <a style={{ color: "green" }} target='blank' href={news.link}>Go Now</a>}</td>
                                </tr>
                            ))
                        ) : (
                            <tr>
                                <td colSpan="1">No Urgent News</td>
                            </tr>
                        )}
                        <tr key={'migration'}>
                            <td>Start Migration From Open Loot <span onClick={() => connectOpenLoot('auth')} style={{ color: "green" }}>Go Now</span></td>
                        </tr>
                        {isQorbOwner &&
                            <tr key={'qorb-owner'}>
                                <td>Qorb Owner Special Soon... <a style={{ color: "green" }} target='blank' href={'https://www.youtube.com/watch?v=Ycx4txSW1hw'}>Sneak Peak</a>{/* <span onClick={() => connectOpenLoot('auth')} style={{ color: "green" }}>Check Now</span>*/}</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default UserProfile;
