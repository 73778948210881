import React, { useEffect, useState } from 'react';
import axios from 'axios';
import qorbi_coin from '../assets/img/qorbi-coin.png';
import { ImmutableXClient } from "@imtbl/imx-sdk";
import { useSphereOneProvider } from '../utils/SphereOneHooks';
import { useNavigate } from 'react-router-dom';
import { Button } from "../components/Button";
import Popup from "../components/Popup";

const qorbiService = require('../context/QorbiService');

const QorbStaking = () => {
    const [qorbStaking, setQorbStaking] = useState(null);
    const [currentQorb, setCurrentQorb] = useState(null);
    const [allQorbs, setAllQorbs] = useState(null);
    const { devicedUsed } = useSphereOneProvider();
    const [isOpenLoot, setIsOpenLoot] = useState(false);
    const [qorbId, setQorbId] = useState();
    const [poolQorbs, setPoolQorbs] = useState(null)
    const [showPopup, setShowPopup] = useState(false);

    const navigate = useNavigate();

    const urlParams = new URLSearchParams(window.location.search);
    const firstID = urlParams.get('qorb');
    const qorbWallet = urlParams.get('wallet');

    const getQorbData = async () => {
        const client = await ImmutableXClient.build({
            publicApiUrl: "https://api.x.immutable.com/v1",
        });
        if (qorbWallet !== undefined) {
            const uuidPattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
            if (uuidPattern.test(qorbWallet)) {
                setIsOpenLoot(true);
                const openLootAssets = await qorbiService.getOpenLootAssets(qorbWallet);
                const qorbAssets = openLootAssets.filter(asset => asset.metadata.name === "Qorb");
                setQorbId(firstID.substring(0, 4));
                setAllQorbs(qorbAssets)
                const filteredQorb = qorbAssets.find(token => token.id === firstID);
                setCurrentQorb(filteredQorb);
                fetchData()
            } else {
                setIsOpenLoot(false);
                const qorbAssetsRequest = await client.getAssets({
                    user: qorbWallet,
                    collection: "0x2d59c7b8d253b826cdba9389be397a68af88cf08",
                });
                if (!qorbAssetsRequest.result.length > 0) {
                    const zkEvm = await axios.get(`https://immutable-mainnet.blockscout.com/api/v2/tokens/0x2bCBb73C73d765A695F972BDd84222ee8ee55f2D/instances?holder_address_hash=${qorbWallet}`);
                    if (zkEvm.data.items.length === 0) {
                        navigate('/my-assets');
                    }
                    const nftMetadataPromises = zkEvm.data.items
                        .map(async (token) => {
                            const tokenId = token.id;
                            try {
                                const metadataResponse = await fetch(
                                    `https://assets.qorbiworld.com/resource/qorb/${tokenId}`
                                );
                                if (!metadataResponse.ok) {
                                    throw new Error(
                                        `Error fetching metadata for token ${tokenId}: ${metadataResponse.statusText}`
                                    );
                                }
                                const metadata = await metadataResponse.json();
                                return {
                                    ...token,
                                    metadata,
                                };
                            } catch (error) {
                                console.error(
                                    `Error fetching metadata for token ${tokenId} in collection qorb:`,
                                    error
                                );
                                return null;
                            }
                        });
                    const nftsWithMetadata = (await Promise.all(nftMetadataPromises)).filter(Boolean);
                    setAllQorbs(nftsWithMetadata)
                    const filteredQorb = nftsWithMetadata.find(token => token.id === firstID);
                    if (!filteredQorb) {
                        navigate('/my-assets');
                    } else {
                        const metadataResponse = await fetch(
                            `https://assets.qorbiworld.com/resource/qorb/${firstID}`
                        );
                        const metadata = await metadataResponse.json();
                        fetchData()
                        setCurrentQorb({
                            ...filteredQorb,
                            metadata,
                        });

                    }
                } else {
                    setAllQorbs(qorbAssetsRequest.result)
                    const filteredQorb = qorbAssetsRequest.result.find(token => token.token_id === firstID);
                    if (!filteredQorb) {
                        // navigate('/my-assets');
                    } else {
                        fetchData()
                        setCurrentQorb(filteredQorb);
                    }
                }
            }
        }
    };

    const fetchData = async () => {
        try {
            const { data } = await axios.get(`https://api.qorbiworld.com/stake/get-stake-qorbs/${qorbWallet}`);
            setQorbStaking(data);
            if (data?.isPoolQorb) {
                const poolQorbsArray = [];
                Object.values(data.groups).forEach((group) => {
                    group.forEach((item) => {
                        if (item.day.endsWith('Enter Pool')) {
                            poolQorbsArray.push(item.tokenId);
                        }
                    });
                });
                setPoolQorbs(poolQorbsArray);
            } else {
                setPoolQorbs([])
            }
        } catch (error) {
            console.error('Error fetching QorX data:', error);
        }
    };

    const addToPool = async () => {
        if (!poolQorbs.includes(firstID)) {
            const flagQorb = await qorbiService.addQorbToPool({
                walletAddress: qorbWallet,
                QorXNftID: firstID,
            })
            if (flagQorb.addedToQorbPool) {
                window.location.reload();
            }
        }
    }

    const playQorb = async () => {
        if (poolQorbs.includes(firstID)) {
            setShowPopup({ title: "Nuh uh!", content: 'This Qorb is already in the Pool, you cannot play with it!' })
        } else {
            //flag for playing, save qorb data on user and flag qorb as playing
        }
    }

    useEffect(() => {
        getQorbData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const renderMobileView = () => {
        return (
            <div style={{ padding: '20px', marginTop: 50, textAlign: 'center', fontFamily: 'SF UI Display, sans-serif', fontWeight: 'medium', fontSize: "18px" }}>
                {showPopup && (
                    <Popup
                        onClose={() => setShowPopup(false)}
                        title={showPopup.title}
                        content={
                            <>
                                <p>
                                    {showPopup.content}
                                </p>
                                <Button label="Ok" onClick={() => setShowPopup(false)} />
                            </>
                        }
                    />
                )}
                {qorbStaking && (
                    <>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <img
                                src={currentQorb?.image_url || currentQorb?.extra?.imageUrl || currentQorb?.metadata?.image}
                                alt="QorX"
                                style={{
                                    width: 'auto',
                                    height: '40vh',
                                    border: '5px solid #2DD881',
                                    borderRadius: '15px',
                                    marginBottom: '20px',
                                }}
                            />
                            <div style={{ color: 'white', fontWeight: 'bold' }}>

                                <div style={{ color: 'white', fontWeight: 'bold', fontSize: '34px' }}>
                                    {`${currentQorb?.name || currentQorb?.metadata?.name || currentQorb?.extra?.attributes[0]?.value} #${isOpenLoot ? qorbId : firstID}`}
                                </div>
                                {Object.keys(qorbStaking?.groups).length > 0 &&
                                    <div style={{ color: '#A6A6A6', fontWeight: 'bold', fontSize: '24px' }}>
                                        Qorbs Owned: {allQorbs.length}
                                    </div>
                                }
                                <br></br>
                                <div className='qorb-earning-container'>
                                    <img src={qorbi_coin} alt="Qorbi_Coin" />
                                    ‎ Qorbi Earned: {Math.floor(qorbStaking.totalReward * 10000) / 10000}
                                </div>
                                {/* <button className={'btnTransparent'} onClick={playQorb} style={{ width: '181.88px', height: '45px', marginBottom: 15 }}>
                                    Play With Qorb
                                </button> */}
                                {Array.isArray(poolQorbs) &&
                                    <button onClick={addToPool}
                                        className={`whitelist-qorbi-button ${poolQorbs.includes(firstID) ? 'whitelist-qorbi-after' : ''}`}
                                    ></button>
                                }
                                <button className={'greenBtn'} onClick={() => setShowPopup({ title: "Almost There!!!", content: 'This option will be activated after the QORBI token is listed. You will be able to transfer to any wallet' })} style={{ width: '181.88px', height: '45px', marginTop: 15 }}>
                                    Withdraw Earning
                                </button>
                            </div>
                        </div>
                        <div style={{ marginTop: '50px', marginRight: '20px', color: "white" }}>
                            <h3 style={{ fontWeight: 'bold', marginBottom: '15px', fontSize: "40px" }}>History of Pledging</h3>
                            <div style={{ width: '100%', marginTop: '10px', textAlign: 'start' }}>
                                <div style={{
                                    display: 'flex', flexDirection: 'row', padding: '10px', backgroundColor: '#181314', borderRadius: '25px',
                                }}>
                                    <div style={{ flex: 1 }}></div>
                                    <div style={{ flex: 4 }}>Date</div>
                                    <div style={{ flex: 2 }}>Token ID</div>
                                </div>
                                {qorbStaking?.groups[firstID]?.length > 0 && qorbStaking.groups[firstID].slice().map((row) => (
                                    <div key={row.tokenId} style={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
                                        <div style={{ flex: 1 }}></div>
                                        <div style={{ flex: 4 }}>{row.day}</div>
                                        <div style={{ flex: 2 }}>{row.tokenId.substring(0, 4)}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </>
                )}
            </div>
        );
    };

    const renderComputerView = () => {
        return (
            <div style={{ display: 'flex', marginTop: 75, marginLeft: 75, fontFamily: 'SF UI Display, sans-serif', fontWeight: 'medium', fontSize: "18px" }}>
                <div style={{ flex: '2', padding: '20px' }}>
                    {showPopup && (
                        <Popup
                            onClose={() => setShowPopup(false)}
                            title={showPopup.title}
                            content={
                                <>
                                    <p>
                                        {showPopup.content}
                                    </p>
                                    <Button label="Ok" onClick={() => setShowPopup(false)} />
                                </>
                            }
                        />
                    )}
                    {qorbStaking && (
                        <>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                <img
                                    src={currentQorb?.image_url || currentQorb?.extra?.imageUrl || currentQorb?.metadata?.image}
                                    alt="QorX"
                                    style={{
                                        width: 'auto',
                                        height: '40vh',
                                        border: '5px solid #2DD881',
                                        borderRadius: '15px',
                                        marginRight: '20px',
                                    }}
                                />
                                <div>
                                    <div style={{ color: 'white', fontWeight: 'bold', fontSize: '34px' }}>
                                        {`${currentQorb?.name || currentQorb?.metadata?.name || currentQorb?.extra?.attributes[0]?.value} #${isOpenLoot ? qorbId : firstID}`}
                                    </div>
                                    {Object.keys(qorbStaking?.groups).length > 0 &&
                                        <div style={{ color: '#A6A6A6', fontWeight: 'bold', fontSize: '24px' }}>
                                            Qorbs Owned: {allQorbs.length}
                                        </div>
                                    }
                                    <br></br>
                                    <div className='qorb-earning-container'>
                                        <img src={qorbi_coin} alt="Qorbi_Coin" />
                                        ‎ Qorbi Earned: {Math.floor(qorbStaking.totalReward * 10000) / 10000}
                                    </div>
                                    {/* <button className={'btnTransparent'} onClick={playQorb} style={{ width: '181.88px', height: '45px', margin: '15px auto', display: 'block' }}>
                                        Play With Qorb
                                    </button> */}
                                    {Array.isArray(poolQorbs) &&
                                        <button onClick={addToPool}
                                            className={`whitelist-qorbi-button ${poolQorbs.includes(firstID) ? 'whitelist-qorbi-after' : ''}`}
                                        ></button>
                                    }
                                    <button className={'greenBtn'} onClick={() => setShowPopup({ title: "Almost There!!!", content: 'This option will be activated after the QORBI token is listed. You will be able to transfer to any wallet' })} style={{ width: '181.88px', height: '45px', margin: '15px auto', display: 'block', }}>
                                        Withdraw Earning
                                    </button>
                                </div>
                            </div>
                            <div style={{ color: 'white', marginTop: 25 }}>
                                <h3 style={{ fontWeight: 'bold', marginBottom: '15px', fontSize: "40px" }}>History of Pledging</h3>
                                <div style={{ width: '100%', marginTop: '10px' }}>
                                    <div style={{
                                        display: 'flex', flexDirection: 'row', padding: '10px', backgroundColor: '#181314', borderRadius: '25px',
                                    }}>
                                        <div style={{ flex: 1, paddingLeft: '100px' }}>Date</div>
                                        <div style={{ flex: 1, paddingLeft: '100px' }}>Token ID</div>
                                        <div style={{ flex: 1, paddingLeft: '100px' }}>Pledging ID</div>
                                    </div>
                                    {qorbStaking?.groups[firstID]?.length > 0 && qorbStaking.groups[firstID].slice().map((row) => (
                                        <div key={row._id} style={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
                                            <div style={{ flex: 1, paddingLeft: '100px' }}>{row.day}</div>
                                            <div style={{ flex: 1, paddingLeft: '100px' }}>{row.tokenId.substring(0, 4)}</div>
                                            <div style={{ flex: 1, paddingLeft: '100px' }}>{row._id}</div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </>
                    )}
                </div>
                {allQorbs?.length > 0 && (
                    <div style={{ flex: '1', padding: '20px', marginTop: '25px', marginRight: '75px' }}>
                        <div style={{ color: 'white', marginTop: 25 }}>
                            <h3 style={{ fontWeight: 'bold', marginBottom: '15px', fontSize: "40px" }}>Pledging Qorbs</h3>
                            <div style={{ width: '100%', margin: '0 auto', marginTop: '10px', overflowX: 'hidden' }}>
                                <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    background: "#1A1919",
                                    border: "1px solid #FFFFFF0D",
                                    borderRadius: "25px",
                                    opacity: "1",
                                    padding: "10px"
                                }}>
                                    <div style={{ flex: 4, paddingLeft: "30px" }}>Name</div>
                                    <div style={{ flex: 2 }}>ID</div>
                                </div>
                                {allQorbs.map((row) => (
                                    <div key={row.token_id} style={{ display: 'flex', flexDirection: 'row', padding: '10px' }}>
                                        <div style={{ flex: 4, paddingLeft: "30px" }}>{row?.name || row?.metadata?.name || row?.extra?.attributes[0]?.value}</div>
                                        <div style={{ flex: 2 }}>{row.token_id || row.id.substring(0, 4)}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                )
                }
            </div >
        );
    };

    return devicedUsed === 'mobile' ? renderMobileView() : renderComputerView();
};

export default QorbStaking;