import React, { useEffect, useState } from 'react';

const Snowfall = () => {
    const [snowflakes, setSnowflakes] = useState([]);

    useEffect(() => {
        const generateSnowflakes = () => {
            const numSnowflakes = 50;
            const flakes = [];

            for (let i = 0; i < numSnowflakes; i++) {
                flakes.push({
                    key: i,
                    size: `${Math.random() * 1.5 + 0.5}rem`,
                    left: `${Math.random() * 100}vw`,
                    animationDuration: `${Math.random() * 5 + 5}s`,
                    delay: `${Math.random() * 5}s`,
                });
            }

            setSnowflakes(flakes);
        };

        generateSnowflakes();
        const interval = setInterval(generateSnowflakes, 10000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="snowflakes" aria-hidden="true">
            {snowflakes.map((flake) => (
                <div
                    key={flake.key}
                    className="snowflake"
                    style={{
                        fontSize: flake.size,
                        left: flake.left,
                        animationDuration: flake.animationDuration,
                        animationDelay: flake.delay,
                        '--fall-duration': flake.animationDuration,
                    }}
                >
                    &#10052;
                </div>
            ))}
        </div>
    );
};

export default Snowfall;
